import type { RouteRecordRaw } from 'vue-router';

const constantRoutes: RouteRecordRaw[] = [
  {
    path: '/deviceList',
    component: () => import('/@/views/deviceList/index.vue'),
    name: 'deviceList',
    meta: { title: '报修' },
  },
  {
    path: '/deviceDetail',
    component: () => import('/@/views/deviceDetail/index.vue'),
    name: 'deviceDetail',
    meta: { title: '设备详情' },
  },
  {
    path: '/oilStatistics',
    component: () => import('/@/views/deviceDetail/oilStatistics.vue'),
    name: 'oilStatistics',
    meta: { title: '运行油耗' },
  },
  {
    path: '/vehicles-list',
    name: 'vehicles-list',
    meta: { title: '机型列表' },
    component: () => import('/@/views/vehicles/vehicles-list.vue'),
  },
  {
    path: '/vehicles-outline',
    name: 'vehicles-outline',
    meta: { title: 'vr看车' },
    component: () => import('/@/views/vehicles/vehicles-outline.vue'),
  },
  {
    path: '/',
    name: 'Root',
    redirect: '/deviceList',
    meta: {
      title: 'Root',
    },
  },
];

// /**
//  * 基础路由
//  * @type { *[] }
//  */
// export const constantRouterMap = [];

export default constantRoutes;
