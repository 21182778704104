import { createApp } from 'vue';

import App from './App.vue';
import { router } from './router';
import { store } from './store';
import './index.css';

// import Vconsole from 'vconsole';
// const vConsole = new Vconsole();

const app = createApp(App);

app.use(store);
app.use(router);
// app.use(vConsole);

app.mount('#app');
